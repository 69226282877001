import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// Import dynamic page libraries
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import DynamicTable from "../dynamic-page";
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";

// Import model-view libraries
import filtersView from "./modals-view/filters";
import sortView from "./modals-view/sort";

// Import redux actions
import {
  fetchSupPurchases as fetchData,
  fetchSupPurchasesFilters as fetchFilters,
  updateBoundFilter,
  updateFilter,
} from "../../store/supply-purchase";

import TableView from "./modals-view/table";

import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import moment from "moment";

// Main Component
const InventoryPurchases = ({ product_id }) => {
  // Define hooks.
  const location = useLocation();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  // First page load.
  useEffect(() => {
    const startPageByFilters = {
      search: "",
      sort: "-created_at",
      page: 1,
      limit: 10,
      filters: {
        ...(product_id && { product_id: product_id }),
      },
      includes: ["supplier,product.brand.assigned,supplyRequest,employee"],
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(
      storeQuery,
      "type=supply_purchases",
      "term"
    );

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.supplyPurchase);
  const tableData = useSelector((state) => state.supplyPurchase.supPurchases);
  const user = useSelector((state) => state.authUser.authUser);
  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState([]);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle form modal.

  // Handle modal.
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [visiblePurchaseModal, setVisiblePurchaseModal] = useState(false);
  const [visibleSinglePurchaseModal, setVisibleSinglePurchaseModal] =
    useState(false);
  const [visibleCreatePaymentModal, setVisibleCreatePaymentModal] =
    useState(false);
  const [visibleAdjustmentModal, setVisibleAdjustmentModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const termParam = searchParams.get("term");
  const [searchTerm, setSearchTerm] = useState(termParam || "");

  const [accounts, setAccounts] = useState([]);

  // Initial sort list
  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);
    setSearchTerm(search);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const handleTableFilters = (filters, bound) => {
    onFilterApply(filters, bound);
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });

    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const exportMapper = (data) => {
    return data?.length
      ? data?.map((item) => {
          return {
            "Supply Purchase Id": item?.id || "-",
            "Purchased At":
              moment(item?.created_at)?.format("YYYY-MM-DD HH:mm:ss") || "-",
            "Product Id": item?.product?.apid || "-",
            "Product Name": item?.product?.title || "-",
            "Product Brand": item?.product?.brand?.name || "-",
            "Product Assign": item?.product?.brand?.assigned?.name || "-",
            "Product Type":
              item?.product?.type === 1 ? "Single" : "Grouped" || "-",
            "Product Image Url": item?.product?.image || "-",
            "Supply Request Id": item?.supply_request?.id || "-",
            "Supplier Name": item?.supplier?.name || "-",
            "Request Url": item?.supply_request?.url || "-",
            Currency: item?.currency?.code || "-",
            "Unit Purchased Price":
              item?.purchase_price?.[item?.currency?.code] || "-",
            "Purchased Quantity": item?.quantity || "-",
            "Purchased Total": item?.sub_total || "-",
            "Estimated Delivery Date": item?.estimated_delivery_date || "-",
            "Purchased Comment": item?.note || item?.comment || "-",
          };
        })
      : [];
  };

  const headers = [
    "Supply Purchase Id",
    "Purchased At",
    "Product Id",
    "Product Name",
    "Product Brand",
    "Product Assign",
    "Product Type",
    "Product Image Url",
    "Supply Request Id",
    "Supplier Name",
    "Request Url",
    "Currency",
    "Unit Purchased Price",
    "Purchased Quantity",
    "Purchased Total",
    "Estimated Delivery Date",
    "Purchased Comment",
  ];

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {!product_id && (
          <>
            <ArbitProductPage.Title
              title={"Inventory Purchases"}
              allowExport={true}
              exported={{
                exportData: selectedRow?.length > 0 ? selectedRow : tableData,
                exportMapper: exportMapper,
                headers: headers,
              }}
              loading={pageData.loading}
              filter={{
                filters: pageData?.boundFilters,
                pageFilters: pageData?.filters?.filters,
                discard: [
                  "only_acceptable",
                  "status",
                  "product",
                  "created_at",
                  "updated_at",
                ],
                items: filtersView(pageData?.filters?.filters),
                callback: (rs, bound) => {
                  onFilterApply(rs, bound);
                },
                count: Object.values(pageData?.filters?.filters ?? {}).filter(
                  (item) =>
                    Array.isArray(item)
                      ? item?.length > 0
                      : item != null && item !== ""
                ).length,
              }}
              sort={{
                items: sortList,
                selected: pageData?.filters?.sort,
              }}
            />

            <div
              className={
                "flex flex-row justify-between px-[16px] py-[4px] items-center"
              }
            >
              <SearchInput
                className="w-[362px]"
                placeholder="Search..."
                onSubmit={onSearchApply}
                defaultValue={pageData?.filters?.search}
              />
            </div>
          </>
        )}

        <ArbitProductPage.Content className="content">
          {!product_id && (
            <style>
              {!product_id
                ? `
                             @media (768px <= width <= 1000px) {
                                .content .contt {
                                    height: calc(100vh - 220px) !important;
                                }
                            }
                            @media (max-width: 767px) {
                                .content .contt {
                                    height: calc(100vh - 314px) !important;
                                }
                            }
                    `
                : `
                    height: 400px !important;
                    `}
            </style>
          )}
          <DynamicTable
            view={TableView}
            mini={!!product_id}
            hiddenFields={product_id ? ["product", "image"] : []}
            data={TableView(tableData, handleTableFilters)}
            onSelect={(row) => {
              setSelectedRow(row);
            }}
            selectedRow={selectedRow}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            loading={pageData.loading}
            style={{
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              hideSelect: true,
              header: {
                className: "flex justify-start !px-4 !py-4 whitespace-nowrap",
                "tracking id": {
                  className: "min-w-min",
                },
                product: {
                  th: " flex justify-center items-center !pr-[47px]",
                },
                "total paid": {
                  th: "!pr-[15px]",
                },
                cost: {
                  th: "flex justify-end pr-1.5",
                },
                logo: {
                  th: "w-[100px]",
                },
                shippingCost: {
                  className: "hidden",
                },
                image: {
                  className: "hidden",
                },
              },
              row: {
                className:
                  "!px-[8px] !px-4 !py-[0px] !h-[64px] !w-[100%] !items-center !justify-center flex text-[12px] ",
                // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                product: {
                  className:
                    "!justify-center !min-w-[300px] w-[100%] !px-[0px] !pl-[8px] !pr-[8px]",
                  td: "w-[100%]",
                },
                "purchase id": {
                  className: "!px-[12px]",
                },
                "purchased at": {
                  className: "!pr-[20px]",
                },
                cost: {
                  className: "!px-[12px]",
                },
                "total paid": {
                  className: "!px-[28px]",
                },
                "supply channel": {
                  className: " ",
                },
                image: {
                  className: "!px-[0px]",
                },
                qty: {
                  className: " !justify-end",
                },
                "supply channel": {
                  className: " !justify-start",
                  td: product_id ? "w-[100%]" : "",
                },

                _actions: { className: "!py-[0px]" },
              },
            }}
          />
        </ArbitProductPage.Content>

        {!product_id && (
          <FooterPagination
            meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
            loading={pageData.loading}
            pageData={pageData}
            onLimit={onHandleLimit}
          />
        )}
      </ArbitProductPage.Page>
    </>
  );
};

export default InventoryPurchases;

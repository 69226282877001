import React from "react";
import { HiExternalLink } from "react-icons/hi";
import moment from "moment";
import { timeLeft } from "../../../utils/helper";
import Util from "../../dynamic-page/util";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import quantityHandler from "../../../utils/quantityHandler";
import priceHandler from "../../../utils/priceHandler";
import StatusSteps from "../../dynamic-page/elements/StatusSteps";
import { BsFillInfoCircleFill } from "react-icons/bs";
import descriptiveContent from "../../../utils/descriptiveContent";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import upperCase from "../../dynamic-page/util/upperCaseString";
import ItemID from "../../dynamic-page/components/ItemID";
import StatusHistory from "../../goods-acceptance/components/SupplyStatusHistory";
import Icons from "../../orders/icons";
import ProductName from "../../dynamic-page/components/productName";
import { HiFilter } from "react-icons/hi";

const TableView = (list, handleTableFilters) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-500 hover:text-gray-300";
    }
  };

  const unitsQty = (item) => {
    if (item === 1) {
      return item + " unit";
    } else {
      return item + " units";
    }
  };
  const defaultCurrency = {
    code: "TRY",
    image: undefined,
    label: "Turkish lira",
    logo: undefined,
    position: "0",
    product: 1,
    symbol: "₺",
    title: "Turkish lira",
    value: 1,
  };

  const handleSupplyCss = (item) => {
    if (item === "WEBSITE") {
      return "bg-blue-100 text-blue-700 ";
    }
    if (item === "WHOLESALER") {
      return "bg-green-100 text-green-700  ";
    }
    if (item === "MANUFACTURER") {
      return "bg-yellow-100 text-yellow-700  ";
    }
  };
  const handleFilterCss = (item) => {
    if (item === "WEBSITE") {
      return "text-blue-300 hover:text-blue-200";
    }
    if (item === "WHOLESALER") {
      return "text-green-300 hover:text-green-200 ";
    }
    if (item === "MANUFACTURER") {
      return " text-yellow-300 hover:text-yellow-200  ";
    }
  };

  const handleImage=(item)=>{
    if (item === "WEBSITE") {
      return <Icons.globeAlt className="w-3 h-3 " />;
    }
    if (item === "WHOLESALER") {
      return <Icons.rectangleGroup className="w-3 h-3 " />;
    }
    if (item === "MANUFACTURER") {
      return <Icons.wrench className="w-3 h-3 " />;
    }
  }

  return {
    list: list,
    theme: {
      "purchased at": (item) => (
        <Util.dateDisplay
          item={item}
          date={new Date(item?.created_at)}
          secondDate={new Date(item?.supply_request?.request_date_time)}
          tooltip={
            <Util.popUp
              toggle={() => (
                <Icons.info className="w-3.5 h-3.5 text-gray-300 hover:text-gray-500" />
              )}
              action="hover"
              forcePosition={"bottom"}
            >
              <div className="flex flex-col gap-1.5 p-1.5 px-2 w-[297px]">
                <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                  Inventory Purchase Cycle Time
                </span>
                <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                  This duration measures the duration from the creation of a
                  supply request to the purchase.
                </span>
              </div>
            </Util.popUp>
          }
        />
      ),
      "purchase id": (item) => (
        <div className="flex flex-col gap-0.5  w-full">
          <div className="flex items-center gap-1">
            <span className="text-[12px] leading-[21px] text-gray-900 font-medium ">
              {item?.id}
            </span>
            <Util.copyText
              className={"text-[12px]"}
              text={item?.id}
              hint={false}
            />
          </div>
          <div className="flex gap-1 items-center">
            <img
              className="w-[18px] h-[18px] object-cover rounded-full"
              src={
                item?.created_by?.profile_image ||
                "/assets/images/non-user2.png"
              }
            />
            <span className="text-[12px] leading-[18px] font-medium text-gray-900 ">
              {item?.created_by?.name}
            </span>
          </div>
        </div>
      ),

      image: (item) => (
        <div
          className={
            "w-[52px] h-[52px] max-h-[52px]  justify-center items-center flex"
          }
        >
          {item?.product?.image ? (
            <ImageMagnifier
              src={item?.product?.image}
              className={"h-[52px] max-h-[52px] w-[52px]"}
            />
          ) : (
            <img
              src={"/assets/images/noImage.png"}
              className={"h-[52px] w-[52px]"}
            />
          )}
        </div>
      ),

      product: (item) => (
        <ProductName
          item={item?.product}
          user={item?.product?.brand?.assigned}
          handleTableFilters={handleTableFilters}
        />
      ),
      "supply channel": (item) => (
        <div className="flex items-center gap-1">
          <div className={`w-6 h-6 rounded-full  flex justify-center items-center ${handleSupplyCss(item?.supplier?.supply_channel_type)}`}>
            {handleImage(item?.supplier?.supply_channel_type)}
          </div>
          {item?.supplier
            ? descriptiveContent(
                <div
                  className={`flex  justify-start text-base font-medium items-center gap-1 max-w-[164px] ${
                    item?.supply_request?.url && "cursor-pointer"
                  }`}
                  onClick={() => {
                    item?.supply_request?.url &&
                      window.open(item?.supply_request?.url, "_blank");
                  }}
                >
                  <span className=" truncate max-w-[128px] text-base font-medium">
                    {item?.supplier?.name}
                  </span>
                  {item?.supply_request?.url && (
                    <HiExternalLink className="w-4 h-4 text-gray-300 hover:text-gray-500 cursor-pointer" />
                  )}
                </div>,
                item?.supplier?.name
              )
            : ""}
        </div>
      ),
      cost: (item) => (
        <div className="w-[100%]  flex-col px-1 justify-start items-end gap-1 inline-flex">
          <div className="justify-center items-center gap-1 inline-flex">
            <div className="text-pink-700 text-[18px] font-bold font-['Inter'] leading-[18px]">
              {priceHandler(item?.purchase_price?.TRY)}
              <span className="text-[16px]">₺</span>
            </div>
          </div>
          <div className="justify-center items-center gap-1 inline-flex">
            <div className="text-pink-700 text-[14px] font-medium  font-['Inter'] leading-none">
              ${priceHandler(item?.purchase_price?.USD)}
            </div>
            <Util.popUp
              toggle={() => (
                <BsFillInfoCircleFill className="w-3 h-3 text-gray-300 hover:text-gray-500" />
              )}
              action={"hover"}
            >
              <div className="w-[186px] p-1 text-gray-500 text-xs font-normal font-['Inter'] leading-[15px]">
                Calculated according to the exchange rate of the day of
                purchase.
              </div>
            </Util.popUp>
          </div>
        </div>
      ),
      "total paid": (item) => (
        <div className=" w-[100%]  px-1 flex-col justify-center items-end gap-1 inline-flex">
          <div className="justify-end items-center gap-1 inline-flex">
            <div className="text-indigo-700 text-[18px] font-bold font-['Inter'] leading-[18px]">
              {quantityHandler(item?.quantity)}
            </div>
          </div>
          <div className="justify-end items-center gap-1 inline-flex">
            <div className="text-indigo-700 text-[14px] font-medium  leading-[14px]">
              {priceHandler(item?.sub_total, defaultCurrency)}
            </div>
          </div>
        </div>
      ),
    },
  };
};
export default TableView;

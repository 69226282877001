import { ReactComponent as backspace } from "./backspace.svg";
import { ReactComponent as bullet } from "./bullet.svg";
import { ReactComponent as buyerNote } from "./buyerNote.svg";
import { ReactComponent as calculator } from "./calculator.svg";
import { ReactComponent as chartPie } from "./chartPie.svg";
import { ReactComponent as check } from "./check.svg";
import { ReactComponent as chevronRight } from "./chevronRight.svg";
import { ReactComponent as clock } from "./clock.svg";
import { ReactComponent as clockReady } from "./clockReady.svg";
import { ReactComponent as collection } from "./collection.svg";
import { ReactComponent as createLabel } from "./createLabel.svg";
import { ReactComponent as edit } from "./edit.svg";
import { ReactComponent as exclamation } from "./exclamation.svg";
import { ReactComponent as goToTrackURL } from "./goToTrackURL.svg";
import { ReactComponent as printLabel } from "./printLabel.svg";
import { ReactComponent as quickShip } from "./quickShip.svg";
import { ReactComponent as shipmentDetail } from "./shipmentDetail.svg";
import { ReactComponent as shippingContent } from "./shippingContent.svg";
import { ReactComponent as shippingDocument } from "./shippingDocument.svg";
import { ReactComponent as showLabel } from "./showLabel.svg";
import { ReactComponent as internalNote } from "./internalNote.svg";
import { ReactComponent as weightBox } from "./weightBox.svg";
import { ReactComponent as weightTarazo } from "./weightTarazo.svg";
import { ReactComponent as xMark } from "./xMark.svg";
import { ReactComponent as boxArrow } from "./boxArrow.svg";
import { ReactComponent as del } from "./del.svg";
import { ReactComponent as star } from "./star.svg";
import { ReactComponent as hideLabel } from "./hideLabel.svg";
import { ReactComponent as addImage } from "./addImage.svg";
import { ReactComponent as adjustment } from "./adjustment.svg";
import { ReactComponent as globe } from "./globe.svg";
import { ReactComponent as home } from "./home.svg";
import { ReactComponent as puzzle } from "./puzzle.svg";
import { ReactComponent as user } from "./user.svg";
import { ReactComponent as arrow } from "./arrow.svg";
import { ReactComponent as link } from "./link.svg";
import { ReactComponent as link2 } from "./link2.svg";
import { ReactComponent as exclamationTin } from "./exclamation_tin.svg";
import { ReactComponent as offerCount } from "./offerCount.svg";
import { ReactComponent as tradingUp } from "./trending-up.svg";
import { ReactComponent as salesNote } from "./salesNote.svg";
import { ReactComponent as fire } from "./fire.svg";
import { ReactComponent as labelsPaidBy } from "./labelsPaidBy.svg";
import { ReactComponent as returnRequest } from "./returnRequest.svg";
import { ReactComponent as azClaim } from "./azClaim.svg";
import { ReactComponent as cube } from "./cube.svg";
import { ReactComponent as truck } from "./truck.svg";
import { ReactComponent as cash } from "./cash.svg";
import { ReactComponent as trendingup } from "./trendingUp.svg";
import { ReactComponent as goToListingView } from "./go-to-lising-view.svg";
import { ReactComponent as goToRepricer } from "./go-to-repricer.svg";
import { ReactComponent as repricerNote } from "./repricer-note.svg";
import { ReactComponent as exclamationFill } from "./exclamationFill.svg";
import { ReactComponent as uploadOutline } from "./upload-outline.svg";
import { ReactComponent as download } from "./download.svg";
import { ReactComponent as exportIcon } from "./cloud-download.svg";
import { ReactComponent as trash } from "./trash.svg";
import { ReactComponent as location } from "./location-marker.svg";
import { ReactComponent as shield } from "./shield-check.svg";
import { ReactComponent as notificationDot } from "./notification-dot.svg";
import { ReactComponent as docText } from "./document-text.svg";
import { ReactComponent as uploadSolid } from "./uploadSolid.svg";
import { ReactComponent as plus } from "./plus.svg";
import { ReactComponent as track } from "./truck-solid.svg";
import { ReactComponent as brokenHeart } from "./heart-crack-solid.svg";
import { ReactComponent as reply } from "./reply.svg";
import { ReactComponent as fileCopy } from "./fileCopy.svg";
import { ReactComponent as info } from "./info.svg";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as close } from "./close.svg";
import { ReactComponent as chevronUp } from "./chevronUp.svg";
import { ReactComponent as chevronDown } from "./chevronDown.svg";
import { ReactComponent as circlePlus } from "./circle-plus.svg";
import { ReactComponent as trashBin } from "./trash-bin.svg";
import { ReactComponent as merge } from "./merge.svg";
import { ReactComponent as requestNote } from "./Request-note.svg";
import { ReactComponent as purchaseNote } from "./Purchase-note.svg";
import { ReactComponent as acceptanceNote } from "./Acceptance-note.svg";
import { ReactComponent as send } from "./send.svg";
import { ReactComponent as chechkCircle } from "./checkh-circle.svg";
import { ReactComponent as arrowRotate } from "./arrowRotate.svg";
import { ReactComponent as mapPin } from "./mapPin.svg";
import { ReactComponent as pencilAlt } from "./pencilAlt.svg";
import { ReactComponent as copyText } from "./copyText.svg";
import { ReactComponent as editOutline } from "./editOutline.svg";
import { ReactComponent as refresh } from "./refresh.svg";
import { ReactComponent as inbox } from "./inbox-full.svg";
import { ReactComponent as receipt_percent } from "./receipt-percent.svg";
import { ReactComponent as copyOrders } from "./copyOrders.svg";
import { ReactComponent as union } from "./union.svg";
import { ReactComponent as globeAlt} from "./globe-alt.svg";
import { ReactComponent as rectangleGroup} from "./rectangle-group.svg";
import { ReactComponent as wrench} from "./wrench.svg";
import { ReactComponent as cloudArrowDown} from "./cloud-arrow-down.svg";

export default {
  backspace,
  bullet,
  search,
  cloudArrowDown,
  globeAlt,
  rectangleGroup,
  wrench,
  chechkCircle,
  buyerNote,
  close,
  pencilAlt,
  info,
  circlePlus,
  calculator,
  copyText,
  chartPie,
  fileCopy,
  check,
  mapPin,
  chevronRight,
  clock,
  clockReady,
  collection,
  createLabel,
  edit,
  exclamation,
  goToTrackURL,
  printLabel,
  quickShip,
  shipmentDetail,
  chevronDown,
  chevronUp,
  shippingContent,
  shippingDocument,
  showLabel,
  internalNote,
  weightBox,
  weightTarazo,
  xMark,
  boxArrow,
  del,
  star,
  hideLabel,
  addImage,
  adjustment,
  globe,
  home,
  puzzle,
  user,
  arrow,
  link,
  link2,
  exclamationTin,
  offerCount,
  tradingUp,
  salesNote,
  fire,
  labelsPaidBy,
  returnRequest,
  azClaim,
  cube,
  truck,
  cash,
  trendingup,
  goToListingView,
  goToRepricer,
  repricerNote,
  exclamationFill,
  uploadOutline,
  download,
  trashBin,
  exportIcon,
  trash,
  location,
  shield,
  notificationDot,
  docText,
  uploadSolid,
  plus,
  track,
  brokenHeart,
  reply,
  merge,
  requestNote,
  purchaseNote,
  acceptanceNote,
  send,
  arrowRotate,
  editOutline,
  refresh,
  inbox,
  receipt_percent,
  copyOrders,
  union,
};
